import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Menus } from './NavBar'
import { PrimaryButton } from '../common/BookingButton'
import BgImageBack from '../../assets/images/promaid/equipment_back.svg'
import BgImageFront from '../../assets/images/promaid/img-equipment.svg'
import BgImageMobile from '../../assets/images/promaid/equipment-bg-mobile.svg'
import BgImageMobileGradient from '../../assets/images/promaid/equipment-gradient.svg'
import BgImageMobileFront from '../../assets/images/promaid/equipment-img-mobile.svg'
import CorrectIcon from '../../assets/images/promaid/icons/correct.svg'
import Line from '../../assets/images/promaid/icons/line-button.svg'
import Slider from '../common/SliderComponent'
import PacketsLPCard from '../common/PacketsLPCard'

import SpSmallPacket from '../../assets/images/promaid/sp-small-packet.png'
import SpLargePacket from '../../assets/images/promaid/sp-large-packet.png'
import PpePacket from '../../assets/images/promaid/ppePacket.png'
import EcolabPacket from '../../assets/images/promaid/ecolabPacket.png'
import ElectriPacket from '../../assets/images/promaid/electriPacket.png'

const Container = styled.div`
  position: relative;
  width: 100%;
  background: #a1d6e8;
`

const BgMoblieContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  right: 0;
  @media (max-width: 414px) {
  }
`

const ImgMoblieContainer = styled.div`
  position: absolute;
  width: 199.33px;
  height: 199px;
  right: 0;
  bottom: 0;
  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    left: 240px;
    top: 150px;
  }
  @media (max-width: 640px) {
    width: 100%;
    left: 210px;
    top: 100px;
  }
  @media (max-width: 414px) {
    left: 190px;
    top: 98px;
  }
  @media (max-width: 375px) {
    left: 170px;
    top: 88px;
  }
  @media (max-width: 320px) {
    width: 155.33px;
    height: 200px;
    left: 160px;
    top: 90px;
  }
`

const Title = styled.h1`
  font-weight: 500;
  font-size: 50px;
  line-height: 65px;
  color: #0179ba;
  @media (max-width: 1024px) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 30px;
  }
  @media (max-width: 640px) {
    font-size: 18px;
    line-height: 20px;
  }
`

const TitlePacket = styled.h3`
  font-size: 30px;
  line-height: 1.2;
  font-weight: 400;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 1.5;
  }

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 1.5;
  }
`

const SubtitleList = styled.p`
  font-style: lighter;
  font-weight: 300;
  font-size: 24px;
  line-height: 38px;
  text-align: right;
  color: #5c5c69;
  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    line-height: 20px;
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const Gradient = styled.div`
  position: absolute;
  /* width: 315px;
  height: 289px; */
  left: 0px;
  /* top: 2172px; */
  height: 100%;
  width: 90%;
  background: linear-gradient(90deg, #f2fcfe -23.5%, #f2fcfe 33.23%, rgba(242, 252, 254, 0) 96.17%);
  @media (min-width: 1537px) {
    width: 100%;
  }
`

const Card = styled.div`
  align-self: center;
  height: 100%;
  width: 300px;
  padding: 5px;
`

const DesktopDiv = styled.div`
  justify-content: center;
  @media (max-width: 1890px) {
    justify-content: flex-start;
  }
  @media (max-width: 1440px) {
    justify-content: flex-start;
  }
`

const packets = [
  {
    title: 'ชุดฉีดพ่นฆ่าเชื้อไร้สาย',
    name: 'เซตเล็ก',
    descriptionTop: '',
    descriptionBottom: '',
    price: '2,599',
    logo: SpSmallPacket,
    theme: 'sp-small',
    detail: 'เริ่มต้นเพียง'
  },
  {
    title: 'ชุดฉีดพ่นฆ่าเชื้อ',
    name: 'เซตใหญ่',
    descriptionTop: '',
    descriptionBottom: '',
    price: '',
    logo: SpLargePacket,
    theme: 'sp-large',
    detail: ''
  },
  {
    title: '',
    name: 'ชุด PPE 3M 4545',
    descriptionTop: 'ชุดป้องกันสารเคมี',
    descriptionBottom: 'และเชื้อโรค',
    price: '',
    logo: PpePacket,
    theme: 'green',
    detail: 'สั่งซื้อ ชุด PPE'
  },
  {
    title: '',
    name: 'น้ำยาฆ่าเชื้อ',
    descriptionTop: 'ECOLAB 22 Multi-Quat',
    descriptionBottom: 'Sanitizer',
    price: '',
    logo: EcolabPacket,
    theme: 'green',
    detail: 'สั่งซื้อ น้ำยาฆ่าเชื้อ'
  },
  {
    title: 'เครื่องพ่นน้ำยาฆ่าเชื้อ',
    name: 'ไร้สาย ไอน้ำนาโน',
    descriptionTop: 'Electri ULV Fogger',
    descriptionBottom: '',
    price: '',
    logo: ElectriPacket,
    theme: 'green',
    detail: 'สั่งซื้อ เครื่องพ่นฆ่าเชื้อ'
  }
]

const Equipment: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectCard, setSelectCard] = useState(packets[0])
  return (
    <>
      <div id={Menus.EQUIPMENT} className="lg:pt-8 bg-primary" />
      <Container>
        {/* background-desktop */}
        <div className="absolute top-0 hidden w-full md:flex">
          <div className="justify-between w-full md:flex md:flex-row">
            <div className="w-1/2 bg-blue-400" />
            <img src={BgImageBack} alt="cover-desktop" />
          </div>
          <div className="absolute bottom-0 hidden w-full md:flex">
            <div className="right-0 justify-end hidden w-11/12 md:flex">
              <div className="bottom-0 justify-end hidden xl:justify-around md:flex">
                <img className="w-1/2 lg:w-8/12 xl:w-full" src={BgImageFront} alt="cover-desktop" />
              </div>
            </div>
          </div>
        </div>
        {/* destop */}
        <DesktopDiv className="relative justify-start hidden w-full pt-4 md:flex md:pt-8 lg:pt-12 xl:pt-16">
          <div className="px-10 md:px-12 xl:px-20">
            <div className="flex flex-col ml-2 md:ml-4 lg:ml-16">
              <div className="pt-2">
                <Title className="leading-none text-primary sm:mb-1 xl:mb-2">
                  ฆ่าเชื้อโรคด้วยตัวเองได้ง่ายๆ
                  <br /> ด้วยอุปกรณ์ฉีดพ่นฆ่าเชื้อ สุดคุ้ม
                </Title>
              </div>
              <div className="flex-col items-start hidden py-2 md:flex lg:py-4 xl:py-6">
                <div className="flex flex-col items-start text-gray-200">
                  <div className="flex flex-row py-1 -mb-2 leading-none">
                    <img className="relative top-0 right-0 flex h-full pr-4" src={CorrectIcon} alt="correct-icon" />
                    <SubtitleList>มั่นใจ ปลอดภัย ให้บ้าน / ออฟฟิศของคุณสะอาด</SubtitleList>
                  </div>
                  <div className="flex flex-row py-1 -mb-2 leading-none">
                    <img className="relative top-0 right-0 flex h-full pr-4" src={CorrectIcon} alt="correct-icon" />
                    <SubtitleList>ปลอดจากเชื้อโควิด-19 ด้วยชุดฉีดพ่นฆ่าเชื้อ</SubtitleList>
                  </div>
                  <div className="flex flex-row py-1 -mb-2 leading-none">
                    <img className="relative top-0 right-0 flex h-full pr-4" src={CorrectIcon} alt="correct-icon" />
                    <SubtitleList>พร้อมน้ำยาและอุปกรณ์แบบครบเซต</SubtitleList>
                  </div>
                </div>
              </div>
              <div className="flex items-center pt-2 xl:pt-4">
                <div className="flex flex-col">
                  <PrimaryButton
                    onClick={() => {
                      window.open('https://bit.ly/2Pln3bV', '_blank')
                    }}
                    className="button-line "
                  >
                    <div className="flex flex-row items-center justify-between -my-2 lg:-my-1 button-line">
                      <p className="text-xl button-line lg:text-2xl">สั่งซื้อด่วน จำนวนจำกัด </p>
                      <div className="flex justify-end w-10 h-10 pl-2 button-line md:w-auto md:h-auto">
                        <img src={Line} alt="line" />
                      </div>
                    </div>
                  </PrimaryButton>
                  <div className="justify-center hidden pt-0 md:flex md:pt-2 lg:pt-6">
                    <p className="text-xl text-blue-300 xl:text-4xl">จัดส่งฟรีทั่วไทย</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DesktopDiv>
        {/* background-moblie */}
        <div className="relative">
          <div className="absolute flex flex-row justify-between w-full md:hidden">
            <Gradient />
            {/* <img className="absolute w-full" src={BgImageMobileGradient} alt="cover-mobile" /> */}
            <img className="w-full" src={BgImageMobile} alt="cover-mobile" />
          </div>
          <ImgMoblieContainer>
            <img className="bottom-0 flex md:hidden" src={BgImageMobileFront} alt="cover-mobile" />
          </ImgMoblieContainer>
          {/* moblie */}
          <div className="relative flex justify-start w-full md:hidden">
            <div className="p-2">
              <div className="flex flex-col ml-2">
                <div className="pt-2 sm:pt-0">
                  <Title className="mb-1 leading-none text-primary">
                    ฆ่าเชื้อโรคด้วยตัวเองได้ง่ายๆ
                    <br /> ด้วยอุปกรณ์ฉีดพ่นฆ่าเชื้อ สุดคุ้ม
                  </Title>
                </div>
                <div className="flex flex-col items-start py-0 sm:py-2 md:hidden">
                  <div className="flex flex-col items-start text-gray-200">
                    <div className="flex flex-row py-1 -mb-2 leading-none">
                      <img className="relative top-0 right-0 flex w-6 pr-2" src={CorrectIcon} alt="correct-icon" />
                      <SubtitleList>มั่นใจ ปลอดภัย ให้บ้าน</SubtitleList>
                    </div>
                    <SubtitleList className="pl-6">/ ออฟฟิศของคุณสะอาด</SubtitleList>
                    <div className="flex flex-row py-1 -mb-2 leading-none">
                      <img className="relative top-0 right-0 flex w-6 pr-2" src={CorrectIcon} alt="correct-icon" />
                      <SubtitleList>ปลอดจากเชื้อโควิด-19</SubtitleList>
                    </div>
                    <SubtitleList className="pl-6">ด้วยชุดฉีดพ่นฆ่าเชื้อ</SubtitleList>
                    <div className="flex flex-row py-1 -mb-2 leading-none">
                      <img className="relative top-0 right-0 flex w-6 pr-2" src={CorrectIcon} alt="correct-icon" />
                      <SubtitleList>พร้อมน้ำยาและอุปกรณ์</SubtitleList>
                    </div>
                    <SubtitleList className="pl-6">แบบครบเซต</SubtitleList>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex flex-col py-1 sm:py-2">
                    <PrimaryButton
                      onClick={() => {
                        window.open('https://bit.ly/2Pln3bV', '_blank')
                      }}
                      className="button-line "
                    >
                      <div className="flex flex-row items-center justify-between -my-2 button-line">
                        <p className="text-xs button-line">สั่งซื้อด่วน จำนวนจำกัด </p>
                        <div className="flex justify-end w-8 h-8 pl-2 sm:w-10 sm:h-10 button-line md:w-auto md:h-auto">
                          <img src={Line} alt="line" />
                        </div>
                      </div>
                    </PrimaryButton>
                    <div className="flex justify-center pt-2 md:hidden">
                      <p className="text-xs text-blue-300 sm:text-base">จัดส่งฟรีทั่วไทย</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative bg-blue-100">
          <div className="w-full md:px-0 lg:px-16 xl:px-20">
            <div className="w-full mt-8 xl:mt-32 lg:mt-24">
              <div className="w-full p-6 xl:px-48">
                <TitlePacket className="flex justify-center text-lg text-center text-blue-300 lg:text-2xl">
                  ชุดอุปกรณ์ฉีดพ่นฆ่าเชื้อ
                </TitlePacket>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="w-full max-w-screen-xl px-0 py-2 sm:px-4 md:px-8 lg:px-10 xl:px-12">
                <div className="px-2 my-2 button-line">
                  <Slider className="w-full button-line" scrollSize={4} tabletSize={43} mobileSize={80}>
                    {packets.map(packet => (
                      <Card
                        className="button-line"
                        key={packet.name}
                        onClick={() => {
                          setSelectCard(packet)
                          setIsOpen(true)
                          window.open('https://bit.ly/2Pln3bV', '__blank')
                        }}
                      >
                        <div className="mt-4 ml-2 mr-12 sm:mr-2">
                          <PacketsLPCard packet={{ ...packet }} />
                        </div>
                      </Card>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>

            <div className="relative flex justify-center mt-2 sm:mt-4">
              <div className="flex items-center">
                <div className="flex flex-col py-2 sm:py-4">
                  <PrimaryButton
                    onClick={() => {
                      window.open('https://bit.ly/2Pln3bV', '_blank')
                    }}
                    className="button-line"
                  >
                    <div className="flex justify-center w-full">
                      <div className="flex flex-row items-center justify-center w-full -my-2 lg:w-3/4 button-line">
                        <p className="flex justify-center text-lg text-center md:text-xl lg:text-2xl button-line">สั่งเลย </p>
                        <div className="flex justify-end w-12 h-12 pl-2 button-line md:w-auto md:h-auto">
                          <img src={Line} alt="line" />
                        </div>
                      </div>
                    </div>
                  </PrimaryButton>
                  <div className="flex justify-center py-4 lg:py-8">
                    <p className="text-2xl text-blue-300 lg:text-4xl md::text-2xl">จัดส่งฟรีทั่วไทย !</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Equipment
